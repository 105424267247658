import axiosInstance from "./Api";

export default {
  async getRevenueByServices(params) {
    return await axiosInstance().get(`/accounts/revenue-by-service`, { params });
  },

  async getAllTransactions(params) {
    return await axiosInstance().get(`/accounts/transactions`, { params });
  },

  // Master
  async getAllServices(params) {
    return await axiosInstance().get(`/accounts/all-services`, { params });
  },

  async getAllAgents(params) {
    return await axiosInstance().get(`/accounts/all-agents`, { params });
  },

  async getAllUniversities(params) {
    return await axiosInstance().get(`/accounts/all-universities`, { params });
  },
  
  async saveCommission(payload) {
    return await axiosInstance().post("/accounts/save-commission", payload);
  },

  async getUniversityCommissions(params) {
    return await axiosInstance().get(`/accounts/get-university-commissions/${ params }`);
  },

  async saveCommissionInStudent(payload) {
    return await axiosInstance().post("/accounts/save-student-commission", payload);
  },

};
